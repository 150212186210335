import {injectable} from "inversify";

/**
 * 
 */
export interface ILogsContainer
{
    /**
     * Adds a log entry to the logs
     * @param logEntry The string log entry to append to the log
     * @constructor
     */
    addEntry: (logEntry: string) => void;

    /**
     * Adds a log entry to the logs and puts a timestamp on the front
     * @param logEntry The string log entry to append to the log
     * @constructor
     */
    addEntryWithTime: (logEntry: string) => void;
    
    /**
     * Gets all the log entries
     * @constructor
     * @returns An array of all log entries
     */
    getAllLogs: () => string[];
    
    /**
     * Gets the number of log entries we have
     * @constructor
     * @returns Number of log entries
     */
    logsCount: () => number;
}

@injectable()
export class LogsContainer implements ILogsContainer {
    private logEntries: string[] = []
    
    addEntry(logEntry: string) {
        this.logEntries.push(logEntry);
        //console.log(this.logsCount() + ": " + logEntry);
    }

    addEntryWithTime(logEntry: string) {
        this.addEntry(new Date().toUTCString() + ": " + logEntry);
    }
    
    getAllLogs(): string[] {
        return this.logEntries;
    }
    
    logsCount(): number {
        return this.logEntries.length;
    }
}